import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Droppable, Link } from 'components/custom';
import { getTextColor } from 'utils/color';
import { getLessonUrl } from 'utils/links';
import { DragAndDropOperationNames } from 'utils/drag-and-drop';
import { FIRST_DAY_OF_THE_WEEK, HOURS_PER_DAY, LAST_DAY_OF_THE_WEEK, } from 'utils/date';
import { getDay } from 'date-fns';
const EMPTY_SLOT = 'EMPTY_SLOT';
const SlotEntries = ({ slots, day, hour, rowsUsedAbove, }) => {
    const lessonList = slots.map((slot, index) => {
        if (slot === EMPTY_SLOT) {
            return (_jsx(Box, { sx: {
                    height: '100%',
                    width: `calc(100% / ${slots.length})`,
                } }, index));
        }
        const lessonSlotSx = {
            background: slot.color,
            display: 'grid',
            height: '100%',
            p: '4px',
            placeItems: 'center',
            textDecoration: 'none',
            zIndex: 1,
        };
        const textSx = {
            color: getTextColor(slot.color),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
        };
        return (_jsx(Droppable, { droppableId: slot.slotId, droppableItemName: slot.name, height: slot.lengthHours > 1 ? 'calc(200% + 2px)' : '100%', operationName: DragAndDropOperationNames.ASSIGN_OBJECT_TO_LESSON, outlineColor: "white", outlinePosition: "inside", width: `calc(100% / ${slots.length})`, zIndex: 1, children: _jsx(Link, { href: getLessonUrl(slot.slotId), title: slot.name, sx: lessonSlotSx, children: _jsx(Box, { sx: textSx, children: slot.name }) }) }, index));
    });
    return (_jsx(Box, { role: "gridcell", sx: {
            border: 'solid 1px',
            borderColor: 'secondary.light',
            display: 'flex',
            gap: '2px',
            gridColumn: day + 1,
            gridRow: rowsUsedAbove + hour,
            textAlign: 'center',
        }, children: lessonList }));
};
const fillEmptySlots = (slotSet) => {
    let maxLength = 0;
    slotSet.forEach((s) => {
        maxLength = Math.max(maxLength, s.length);
    });
    slotSet.forEach((slot) => {
        for (let i = 0; i < maxLength; i++) {
            if (!(i in slot)) {
                slot[i] = EMPTY_SLOT;
            }
        }
    });
};
const CourseDashboardCells = ({ data, startDate, onlyOneDay, rowsUsedAbove, }) => {
    const result = [];
    const firstDay = onlyOneDay ? getDay(startDate) : FIRST_DAY_OF_THE_WEEK;
    const lastDay = onlyOneDay ? firstDay : LAST_DAY_OF_THE_WEEK;
    const connectedSlots = new Set();
    for (let day = firstDay; day <= lastDay; day++) {
        let nextHourSlots = [];
        for (let hour = 1; hour <= HOURS_PER_DAY; hour++) {
            const dataForSlot = data.filter((c) => c.weekday === day && c.hourInDay === hour);
            const slots = nextHourSlots;
            nextHourSlots = [];
            let index = 0;
            let hasDoubleHour = false;
            for (const d of dataForSlot) {
                while (slots[index] === EMPTY_SLOT) {
                    index++;
                }
                slots[index] = d;
                if (d.lengthHours > 1) {
                    hasDoubleHour = true;
                    nextHourSlots[index] = EMPTY_SLOT;
                    connectedSlots.add(slots);
                    connectedSlots.add(nextHourSlots);
                }
                index++;
            }
            if (!hasDoubleHour) {
                fillEmptySlots(connectedSlots);
                connectedSlots.clear();
            }
            result.push(_jsx(SlotEntries, { slots: slots, day: onlyOneDay ? 1 : day, hour: hour, rowsUsedAbove: rowsUsedAbove }, `${day}-${hour}`));
        }
    }
    return result;
};
export default CourseDashboardCells;
