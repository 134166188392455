import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { getSlotHours } from 'utils/date';
const HourInDayCell = ({ slotNumber, rowsUsedAbove, }) => {
    const [start, end] = getSlotHours(slotNumber);
    const hourSx = {
        color: (theme) => theme.palette.primary.light,
        fontSize: '0.8rem',
        visibility: 'hidden',
    };
    return (_jsx(Box, { role: "rowheader", sx: {
            border: '1px solid',
            borderColor: 'secondary.light',
            display: 'grid',
            gridColumn: 1,
            gridRow: rowsUsedAbove + slotNumber,
        }, children: _jsxs(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                textAlign: 'center',
            }, children: [_jsx(Box, { children: _jsx(Typography, { sx: hourSx, children: start }) }), _jsx(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }, children: _jsxs(Typography, { sx: { fontSize: '1.1rem' }, children: [slotNumber, "."] }) }), _jsx(Box, { children: _jsx(Typography, { sx: hourSx, children: end }) })] }) }));
};
export default HourInDayCell;
