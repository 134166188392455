import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { eachDayOfInterval, nextFriday, nextMonday } from 'date-fns';
import React, { useMemo } from 'react';
import { convertIntervalToSlot, getSeqArray, HOURS_PER_DAY, LAST_DAY_OF_THE_WEEK, } from 'utils/date';
import DayOfTheWeekCell from './DayOfTheWeekCell';
import HourInDayCell from './HourInDayCell';
import CourseDashboardCells from './CourseDashboardCells';
import CoursePlannerCells from './CoursePlannerCells';
export const convertLessonsToSlots = (lessons) => {
    return lessons.map((lesson) => {
        const { id, startDate, endDate, course: { name, color }, } = lesson;
        return {
            color,
            name,
            slotId: id,
            ...convertIntervalToSlot({
                end: endDate,
                start: startDate,
            }),
        };
    });
};
const WeeklySchedule = ({ lessonData: data, freeDaysItems = [], freeDaysRowCount = 0, dateOfMonday = null, onDeleteCourseSuccess, onPlanCourseSuccess, isPlanCourseView = false, onHourLengthChange, onlyOneDay = false, }) => {
    const daysOfTheWeek = useMemo(() => {
        const start = dateOfMonday !== null && dateOfMonday !== void 0 ? dateOfMonday : nextMonday(Date.now());
        const end = onlyOneDay ? start : nextFriday(start);
        return eachDayOfInterval({
            end,
            start,
        }).map((date, index) => (_jsx(DayOfTheWeekCell, { date: dateOfMonday && date, day: index + 1 }, index)));
    }, [dateOfMonday, onlyOneDay]);
    const hoursInDay = useMemo(() => getSeqArray(HOURS_PER_DAY).map((value, index) => (_jsx(HourInDayCell, { slotNumber: value, rowsUsedAbove: freeDaysRowCount + 1 }, index))), [freeDaysRowCount]);
    const freeDaysCells = React.useMemo(() => {
        const firstColumn = 1;
        const cells = freeDaysItems.map((item) => (_jsx(Box, { sx: {
                alignContent: 'center',
                background: (theme) => theme.palette.success.main,
                border: '1px solid white',
                borderBottomLeftRadius: item.containsStart ? '0.7rem' : 'none',
                borderBottomRightRadius: item.containsEnd ? '0.7rem' : 'none',
                borderTopLeftRadius: item.containsStart ? '0.7rem' : 'none',
                borderTopRightRadius: item.containsEnd ? '0.7rem' : 'none',
                color: 'white',
                gridColumnEnd: firstColumn + 1 + (onlyOneDay ? 1 : item.lastWeekDay),
                gridColumnStart: firstColumn + (onlyOneDay ? 1 : item.firstWeekDay),
                gridRow: item.row + 1,
                height: '1.6rem',
                overflow: 'hidden',
                paddingX: '1rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }, children: item.name }, item.id)));
        return cells;
    }, [freeDaysItems, onlyOneDay]);
    const courses = useMemo(() => {
        if (isPlanCourseView) {
            return (_jsx(CoursePlannerCells, { data: data, onDeleteCourseSuccess: onDeleteCourseSuccess, onHourLengthChange: onHourLengthChange, onPlanCourseSuccess: onPlanCourseSuccess }));
        }
        return (_jsx(CourseDashboardCells, { data: data, startDate: dateOfMonday !== null && dateOfMonday !== void 0 ? dateOfMonday : nextMonday(Date.now()), onlyOneDay: onlyOneDay, rowsUsedAbove: freeDaysRowCount + 1 }));
    }, [
        data,
        dateOfMonday,
        freeDaysRowCount,
        onlyOneDay,
        isPlanCourseView,
        onDeleteCourseSuccess,
        onPlanCourseSuccess,
        onHourLengthChange,
    ]);
    return (_jsxs(Box, { sx: {
            display: 'grid',
            gridTemplateColumns: `minmax(50px, 75px) repeat(${onlyOneDay ? 1 : LAST_DAY_OF_THE_WEEK}, minmax(0, 1fr))`,
            gridTemplateRows: `repeat(${HOURS_PER_DAY + freeDaysRowCount + 1}, auto`,
        }, role: "grid", children: [daysOfTheWeek, freeDaysCells, hoursInDay, courses] }));
};
export default WeeklySchedule;
