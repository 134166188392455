import { useSchoolFreeDaysQuery } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
import { getDay } from 'date-fns';
import { getApiStringFromDate, getDateFromApiString } from 'utils/date';
export const useSchoolFreeDaysQueryApi = (from, to) => {
    var _a, _b;
    const { data, loading } = useSchoolFreeDaysQuery(getBaseOptions({
        from: getApiStringFromDate(from),
        to: getApiStringFromDate(to),
    }));
    const fromWeekDay = getDay(from);
    const toWeekDay = getDay(to);
    const vacations = [...((_a = data === null || data === void 0 ? void 0 : data.schoolFreeDays.vacations) !== null && _a !== void 0 ? _a : [])];
    vacations.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
    const lines = [];
    for (const vacation of vacations) {
        const vacationStartDate = getDateFromApiString(vacation.startDate);
        const vacationEndDate = getDateFromApiString(vacation.endDate);
        const firstWeekDay = vacationStartDate <= from ? fromWeekDay : getDay(vacationStartDate);
        const lastWeekDay = vacationEndDate >= to ? toWeekDay : getDay(vacationEndDate);
        let added = false;
        for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
            const line = lines[lineIndex];
            if (line.every((item) => firstWeekDay > item.lastWeekDay || lastWeekDay < item.firstWeekDay)) {
                line.push({
                    id: vacation.id,
                    name: vacation.name,
                    containsEnd: vacationEndDate <= to,
                    containsStart: vacationStartDate >= from,
                    firstWeekDay,
                    lastWeekDay,
                    row: lineIndex + 1,
                });
                added = true;
                break;
            }
        }
        if (!added) {
            lines.push([
                {
                    id: vacation.id,
                    name: vacation.name,
                    containsEnd: vacationEndDate <= to,
                    containsStart: vacationStartDate >= from,
                    firstWeekDay,
                    lastWeekDay,
                    row: lines.length + 1,
                },
            ]);
        }
    }
    for (const holiday of (_b = data === null || data === void 0 ? void 0 : data.schoolFreeDays.holidays) !== null && _b !== void 0 ? _b : []) {
        const holidayDate = getDateFromApiString(holiday.date);
        const weekday = getDay(holidayDate);
        let added = false;
        for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
            const line = lines[lineIndex];
            if (line.every((item) => weekday > item.lastWeekDay || weekday < item.firstWeekDay)) {
                line.push({
                    id: holiday.id,
                    name: holiday.name,
                    containsEnd: true,
                    containsStart: true,
                    firstWeekDay: weekday,
                    lastWeekDay: weekday,
                    row: lineIndex + 1,
                });
                added = true;
                break;
            }
        }
        if (!added) {
            lines.push([
                {
                    id: holiday.id,
                    name: holiday.name,
                    containsEnd: true,
                    containsStart: true,
                    firstWeekDay: weekday,
                    lastWeekDay: weekday,
                    row: lines.length + 1,
                },
            ]);
        }
    }
    return {
        items: Array().concat(...lines),
        loading,
        rowCount: lines.length,
    };
};
