import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DoubleArrow } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useLessonsQueryApi, useSchoolFreeDaysQueryApi } from 'api';
import { LoadingWrapper, WeeklySchedule } from 'components/custom';
import { convertLessonsToSlots } from 'components/custom/WeeklySchedule';
import { addWeeks, endOfDay, format, isEqual, nextFriday, startOfWeek, } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
let persistedInterval = null;
const CalendarForDashboard = ({ startDate, }) => {
    const { t } = useTranslation('timetable', { keyPrefix: 'weeklyCalendar' });
    const now = startDate !== null && startDate !== void 0 ? startDate : new Date();
    const monday = startOfWeek(now, { weekStartsOn: 1 });
    const friday = endOfDay(nextFriday(monday));
    const intervalForCurrentWeek = {
        end: friday,
        start: monday,
    };
    const [interval, setInterval] = React.useState(persistedInterval !== null && persistedInterval !== void 0 ? persistedInterval : intervalForCurrentWeek);
    const lessonsStatus = useLessonsQueryApi({
        from: interval.start,
        to: interval.end,
    });
    const lessonData = lessonsStatus.data.length
        ? convertLessonsToSlots(lessonsStatus.data)
        : [];
    const freeDaysStatus = useSchoolFreeDaysQueryApi(interval.start, interval.end);
    const displayedDateRange = `${format(interval.start, 'dd')}. - ${format(interval.end, 'dd. LLLL y')}`;
    const setWeekToCurrentWeek = () => {
        persistedInterval = intervalForCurrentWeek;
        setInterval(intervalForCurrentWeek);
    };
    const handleChangeWeek = (deltaWeeks) => {
        const newMonday = addWeeks(interval.start, deltaWeeks);
        const nextInterval = {
            end: endOfDay(nextFriday(newMonday)),
            start: newMonday,
        };
        persistedInterval = nextInterval;
        setInterval(nextInterval);
    };
    return (_jsxs(Stack, { spacing: 2, children: [_jsxs(Stack, { direction: "row", sx: {
                    alignItems: 'center',
                }, children: [_jsxs(Box, { sx: {
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'center',
                        }, children: [_jsx(IconButton, { "aria-label": t('previousWeekButton'), color: "secondary", onClick: () => {
                                    handleChangeWeek(-1);
                                }, sx: { px: 6, py: 2 }, children: _jsx(DoubleArrow, { sx: { rotate: '180deg' } }) }), _jsx(Typography, { component: "span", fontSize: "1.1rem", fontWeight: "bold", sx: { minWidth: '20ch', textAlign: 'center' }, "data-testid": "dateRange", children: displayedDateRange }), _jsx(IconButton, { "aria-label": t('nextWeekButton'), onClick: () => {
                                    handleChangeWeek(1);
                                }, color: "secondary", sx: { px: 6, py: 2 }, children: _jsx(DoubleArrow, {}) })] }), _jsx(Button, { color: "secondary", variant: "outlined", sx: { height: '2rem' }, disabled: isEqual(interval.start, intervalForCurrentWeek.start), onClick: setWeekToCurrentWeek, children: t('todayButton') })] }), _jsx(LoadingWrapper, { status: [lessonsStatus, freeDaysStatus], children: _jsx(WeeklySchedule, { lessonData: lessonData, dateOfMonday: interval.start, freeDaysItems: freeDaysStatus.items, freeDaysRowCount: freeDaysStatus.rowCount }) })] }));
};
export default CalendarForDashboard;
