import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dashboard, Delete, KeyboardDoubleArrowLeft, } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { getCreateTimetableSlotsMutationOptions, getDeleteTimetableSlotsMutationOptions, getUpdateTimetableSlotMutationOptions, getUpdateTimetableSlotsMutationOptions, onValidationError, SKIP, useCoursesQueryApi, useCreateTimetableSlotsMutation, useDeleteTimetableSlotsMutation, useTimetableSlotsQueryApi, useTimetablesQueryApi, useUpdateTimetableSlotMutation, useUpdateTimetableSlotsMutation, } from 'api';
import { Button, DraggableCourseSlot, Droppable, LoadingWrapper, Select, TimetableDataGrid, WeeklySchedule, } from 'components/custom';
import { isEmpty } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { findMostRelevantTimePeriod, getWeekDayName } from 'utils/date';
import { DragAndDropOperationNames, DroppableIds } from 'utils/drag-and-drop';
import { setTitle } from 'utils/head';
import { getSelectOptions } from 'utils/input';
import { HOME } from 'utils/links';
import { log, Severity } from 'utils/logger';
import DeleteCourseSlotsDialog from './DeleteCourseSlotsDialog';
import DeleteSlotDialog from './DeleteSlotDialog';
import SaveSlotDialog from './SaveSlotDialog';
const extractCourseSlots = (timetableSlots) => {
    return timetableSlots.map(({ hourInDay, id, lengthHours, weekday }) => ({
        hourInDay,
        id,
        lengthHours,
        weekday,
    }));
};
const getWeeklyScheduleData = (timetableSlots, courses) => {
    const result = [];
    timetableSlots.forEach((timetableSlot) => {
        const course = courses.find((item) => item.id === timetableSlot.courseId);
        if (!course) {
            return;
        }
        const otherCourseSlots = extractCourseSlots(timetableSlots.filter((item) => item.courseId === course.id && item.id !== timetableSlot.id));
        result.push({
            color: course.color,
            courseId: course.id,
            hourInDay: timetableSlot.hourInDay,
            lengthHours: timetableSlot.lengthHours,
            name: course.name,
            otherCourseSlots,
            slotId: timetableSlot.id,
            timetableId: timetableSlot.timetableId,
            weekday: timetableSlot.weekday,
        });
    });
    return result;
};
const getCoursesWithSlots = (courses, timetableSlots) => {
    return [...courses].map((course) => ({
        ...course,
        slots: extractCourseSlots(timetableSlots.filter((timetableSlot) => timetableSlot.courseId === course.id)),
    }));
};
const Lessons = () => {
    const { t: headTranslate } = useTranslation('timetable', {
        keyPrefix: 'head',
    });
    React.useEffect(() => {
        setTitle(headTranslate('title.lessons'));
    }, [headTranslate]);
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.lessons',
    });
    const { t: commonTranslation } = useTranslation('common');
    const rootRef = React.useRef();
    const { handlePrev } = useOutletContext();
    const { schoolYearId = '' } = useParams();
    const [activeCourse, setActiveCourse] = React.useState(null);
    const [courseToClearSlots, setCourseToClearSlots] = React.useState(undefined);
    const [courseWithSlotToDelete, setCourseWithSlotToDelete] = React.useState(undefined);
    const [activeTimetableId, setActiveTimetableId] = React.useState('');
    const timetablesStatus = useTimetablesQueryApi({ schoolYearId });
    const coursesStatus = useCoursesQueryApi(schoolYearId ? { schoolYearId } : SKIP);
    const timetableSlotsStatus = useTimetableSlotsQueryApi(isEmpty(activeTimetableId)
        ? SKIP
        : {
            timetableId: activeTimetableId,
        });
    const weeklyScheduleData = coursesStatus.data.length
        ? getWeeklyScheduleData(timetableSlotsStatus.data, coursesStatus.data)
        : [];
    const [addSlots, statusAddSlots] = useCreateTimetableSlotsMutation();
    const [editSlots, statusEditSlots] = useUpdateTimetableSlotsMutation();
    const [deleteSlots, statusDeleteSlots] = useDeleteTimetableSlotsMutation();
    const [editSlot, statusEditSlot] = useUpdateTimetableSlotMutation();
    const saveSlotOnDrag = (droppableId, courseWithSlots) => {
        const [weekday, hourInDay] = droppableId
            .split('-')
            .map((param) => Number(param));
        addSlots(getCreateTimetableSlotsMutationOptions({
            courseId: courseWithSlots.id,
            slots: [
                {
                    hourInDay,
                    lengthHours: 1,
                    weekday,
                },
            ],
            timetableId: activeTimetableId,
        }))
            .then(() => {
            enqueueSnackbar(t('planSuccessMessage', {
                courseName: courseWithSlots.name,
                hourInDay,
                weekday: getWeekDayName(weekday),
            }), { variant: 'success' });
        })
            .catch(onValidationError);
    };
    const updateSlotOnDrag = (droppableId, courseWithSlots) => {
        const [weekday, hourInDay] = droppableId
            .split('-')
            .map((param) => Number(param));
        editSlot(getUpdateTimetableSlotMutationOptions({
            hourInDay,
            id: courseWithSlots.slotId,
            lengthHours: 1,
            weekday,
        }))
            .then(() => {
            enqueueSnackbar(t('planSuccessMessage', {
                courseName: courseWithSlots.name,
                hourInDay,
                weekday: getWeekDayName(weekday),
            }), { variant: 'success' });
        })
            .catch(onValidationError);
    };
    const deleteSlotOnDrag = (courseWithSlots) => {
        var _a;
        if (!courseWithSlots.courseId) {
            log('Course ID is missing', {
                dump: courseWithSlots,
                scope: 'deleteSlotOnDrag',
                severity: Severity.ERROR,
            });
            return;
        }
        if (isEmpty(courseWithSlots.otherCourseSlots)) {
            deleteSlots(getDeleteTimetableSlotsMutationOptions({
                courseId: courseWithSlots.courseId,
                timetableId: activeTimetableId,
            }))
                .then(() => {
                enqueueSnackbar(t('deleteSuccessMessage', {
                    courseName: courseWithSlots.name,
                    hourInDay: courseWithSlots.hourInDay,
                    weekday: getWeekDayName(courseWithSlots.weekday),
                }), { variant: 'success' });
            })
                .catch(onValidationError);
        }
        else {
            editSlots(getUpdateTimetableSlotsMutationOptions({
                courseId: courseWithSlots.courseId,
                slots: (_a = courseWithSlots.otherCourseSlots) !== null && _a !== void 0 ? _a : [],
                timetableId: activeTimetableId,
            }))
                .then(() => {
                enqueueSnackbar(t('deleteSuccessMessage', {
                    courseName: courseWithSlots.name,
                    hourInDay: courseWithSlots.hourInDay,
                    weekday: getWeekDayName(courseWithSlots.weekday),
                }), { variant: 'success' });
            })
                .catch(onValidationError);
        }
    };
    const memoizedUpdateSlotOnDrag = React.useCallback(updateSlotOnDrag, [
        editSlot,
        t,
    ]);
    const memoizedDeleteSlotOnDrag = React.useCallback(deleteSlotOnDrag, [
        activeTimetableId,
        deleteSlots,
        editSlots,
        t,
    ]);
    React.useEffect(() => {
        var _a, _b;
        if (!activeTimetableId) {
            setActiveTimetableId((_b = (_a = findMostRelevantTimePeriod(timetablesStatus.data)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '');
        }
    }, [activeTimetableId, timetablesStatus.data]);
    const handleEditClick = (course) => {
        var _a;
        const sortedSlots = [...((_a = course.slots) !== null && _a !== void 0 ? _a : [])].sort((a, b) => {
            if (a.weekday > b.weekday) {
                return 1;
            }
            if (a.weekday < b.weekday) {
                return -1;
            }
            return a.hourInDay > b.hourInDay ? 1 : -1;
        });
        setActiveCourse({ ...course, slots: sortedSlots });
    };
    const onHourLengthChange = (diff, course) => {
        editSlot(getUpdateTimetableSlotMutationOptions({
            hourInDay: course.hourInDay,
            id: course.slotId,
            lengthHours: course.lengthHours + diff,
            weekday: course.weekday,
        }))
            .then(() => {
            enqueueSnackbar(t('slotHourChangeSuccessMessage', {
                courseName: course.name,
                hourInDay: course.hourInDay,
                weekday: getWeekDayName(course.weekday),
            }), { variant: 'success' });
        })
            .catch(onValidationError);
    };
    const coursesWithSlots = getCoursesWithSlots(coursesStatus.data, timetableSlotsStatus.data);
    return (_jsxs(LoadingWrapper, { overlay: rootRef, mutation: [
            statusAddSlots,
            statusEditSlot,
            statusDeleteSlots,
            statusEditSlots,
        ], status: [timetablesStatus, coursesStatus, timetableSlotsStatus], children: [_jsxs(Box, { ref: rootRef, sx: {
                    alignItems: 'start',
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: '2fr 1fr',
                }, children: [_jsx(WeeklySchedule, { lessonData: weeklyScheduleData, onPlanCourseSuccess: memoizedUpdateSlotOnDrag, onDeleteCourseSuccess: setCourseWithSlotToDelete, onHourLengthChange: onHourLengthChange, isPlanCourseView: true }), _jsxs(Stack, { spacing: 2, sx: { alignSelf: 'stretch' }, children: [_jsx(Select, { label: t('timetableField.label'), placeholder: t('timetableField.placeholder'), onChange: (e) => {
                                    setActiveTimetableId(e.target.value);
                                }, value: activeTimetableId, name: "timetableId", options: getSelectOptions(timetablesStatus.data) }), _jsx(Divider, {}), _jsx(Droppable, { droppableId: DroppableIds.DELETE_LESSON, operationName: DragAndDropOperationNames.DELETE_LESSON, outlinePosition: "outside", tooltipTitle: _jsxs(Stack, { direction: "row", alignItems: "center", spacing: 2, sx: { p: 2 }, children: [_jsx(Delete, {}), _jsx(Typography, { children: t('deleteTimetableSlotDropTooltip') })] }), children: _jsx(TimetableDataGrid, { "data-testid": "lessons", nameColumn: {
                                        field: 'name',
                                        flex: 0.6,
                                        renderCell: ({ row, }) => (_jsx(DraggableCourseSlot, { courseWithSlots: row, onPlanCourseSuccess: saveSlotOnDrag })),
                                    }, label: t('sectionTitle'), rows: coursesWithSlots, onEditClick: handleEditClick, onDeleteClick: setCourseToClearSlots, isDeleteDisabled: (id) => {
                                        var _a, _b;
                                        const slotsCount = (_b = (_a = coursesWithSlots.find((course) => {
                                            return course.id === id;
                                        })) === null || _a === void 0 ? void 0 : _a.slots) === null || _b === void 0 ? void 0 : _b.length;
                                        return !slotsCount;
                                    }, noItemsMessage: t('noTimetable'), compactButtons: true }) }), _jsxs(Stack, { direction: "row", justifyContent: "end", spacing: 2, children: [_jsx(Button, { variant: "contained", color: "error", onClick: handlePrev(schoolYearId), startIcon: _jsx(KeyboardDoubleArrowLeft, {}), children: commonTranslation('body.button.back') }), _jsx(Button, { variant: "contained", color: "secondary", href: HOME, startIcon: _jsx(Dashboard, {}), children: t('toDashboardButton') })] })] })] }), _jsx(SaveSlotDialog, { activeCourse: activeCourse, close: () => {
                    setActiveCourse(null);
                }, timetableId: activeTimetableId }), _jsx(DeleteCourseSlotsDialog, { courseToClearSlots: courseToClearSlots, setCourseToClearSlots: setCourseToClearSlots, timetableId: activeTimetableId }), _jsx(DeleteSlotDialog, { course: courseWithSlotToDelete, onConfirm: (course) => {
                    setCourseWithSlotToDelete(undefined);
                    memoizedDeleteSlotOnDrag(course);
                }, onClose: () => {
                    setCourseWithSlotToDelete(undefined);
                } })] }));
};
export default Lessons;
