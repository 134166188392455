import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddCircle, Close, More } from '@mui/icons-material';
import { Autocomplete, Box, Chip, ListItem, Stack, Typography, } from '@mui/material';
import { isLoading, useTagsQueryApi, useUpdateObjectTagsBulkMutation, getUpdateObjectTagsBulkMutationOptions, } from 'api';
import { Button, ConfirmationDialog, LoadingWrapper, TextFormItem, } from 'components/custom';
import { find } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
const LISTBOX_ROW_HEIGHT = 5.5;
const LISTBOX_ROWS_NUMBER = 2;
const TagManagementDialog = ({ objectsToEditTags, onClose, open, translationNamespace, }) => {
    const { t } = useTranslation(translationNamespace, {
        keyPrefix: 'cmp.TagManagementDialog',
    });
    const [tagName, setTagName] = React.useState('');
    const [tagsFullyAssigned, setTagsFullyAssigned] = React.useState([]);
    const [tagsFullyAssignedUnmodified, setTagsFullyAssignedUnmodified] = React.useState([]);
    const [tagsPartiallyAssigned, setTagsPartiallyAssigned] = React.useState([]);
    const [tagsPartiallyAssignedUnmodified, setTagsPartiallyAssignedUnmodified] = React.useState([]);
    React.useEffect(() => {
        var _a, _b;
        const fullyAssigned = [];
        const partiallyAssigned = [];
        const tagCount = {};
        for (const obj of objectsToEditTags) {
            for (const tag of (_a = obj.tags) !== null && _a !== void 0 ? _a : []) {
                // Trimming is done, because before version 1.5 we created some not trimmed tags.
                const tagTrimmed = tag.value.trim();
                tagCount[tagTrimmed] = ((_b = tagCount[tagTrimmed]) !== null && _b !== void 0 ? _b : 0) + 1;
            }
        }
        for (const tag in tagCount) {
            if (tagCount[tag] === objectsToEditTags.length) {
                fullyAssigned.push(tag);
            }
            else {
                partiallyAssigned.push(tag);
            }
        }
        setTagsFullyAssigned(fullyAssigned);
        setTagsFullyAssignedUnmodified(fullyAssigned);
        setTagsPartiallyAssigned(partiallyAssigned);
        setTagsPartiallyAssignedUnmodified(partiallyAssigned);
        setTagName('');
    }, [objectsToEditTags]);
    const hasSomethingChanged = React.useMemo(() => {
        if (tagsFullyAssigned.length !== tagsFullyAssignedUnmodified.length ||
            tagsPartiallyAssigned.length !== tagsPartiallyAssignedUnmodified.length) {
            return true;
        }
        return !tagsFullyAssigned.every((currentTag) => tagsFullyAssignedUnmodified.includes(currentTag));
    }, [
        tagsFullyAssigned,
        tagsFullyAssignedUnmodified,
        tagsPartiallyAssigned,
        tagsPartiallyAssignedUnmodified,
    ]);
    const statusTags = useTagsQueryApi();
    const [updateObjectTags, statusUpdateObjectTags] = useUpdateObjectTagsBulkMutation();
    const addTag = (tagToAdd) => {
        const tag = tagToAdd.trim();
        setTagName('');
        if (tag && !tagsFullyAssigned.includes(tag)) {
            setTagsFullyAssigned([...tagsFullyAssigned, tag]);
            const newPartial = tagsPartiallyAssigned.filter((currentTag) => currentTag !== tag);
            setTagsPartiallyAssigned(newPartial);
        }
    };
    const unassignTag = (tag) => {
        const newFully = tagsFullyAssigned.filter((currentTag) => currentTag !== tag);
        const newPartial = tagsPartiallyAssigned.filter((currentTag) => currentTag !== tag);
        setTagsFullyAssigned(newFully);
        setTagsPartiallyAssigned(newPartial);
    };
    const handleSubmit = () => {
        const updates = objectsToEditTags.map((obj) => {
            const tagsForThisObject = [...tagsFullyAssigned];
            tagsPartiallyAssigned.forEach((tag) => {
                if (obj.tags && find(obj.tags, ['value', tag])) {
                    tagsForThisObject.push(tag);
                }
            });
            const resultTags = [];
            for (const tag of tagsForThisObject) {
                const tagObject = statusTags.data.find((item) => item.value.trim() === tag);
                resultTags.push({ id: tagObject === null || tagObject === void 0 ? void 0 : tagObject.id, value: tag });
            }
            return { id: obj.id, tags: resultTags };
        });
        void updateObjectTags(getUpdateObjectTagsBulkMutationOptions(updates)).then(() => {
            onClose();
            enqueueSnackbar(t('alerts.success'), { variant: 'success' });
        });
    };
    return (_jsx(ConfirmationDialog, { titleProps: {
            Icon: AddCircle,
            text: objectsToEditTags.length <= 1
                ? t('title')
                : t('titleMultiObjects', { objectCount: objectsToEditTags.length }),
        }, cancelButtonProps: { onClick: onClose }, confirmButtonProps: {
            disabled: !!tagName ||
                !hasSomethingChanged ||
                isLoading(statusUpdateObjectTags),
            onClick: handleSubmit,
        }, onClose: onClose, open: open, fullWidth: true, children: _jsx(LoadingWrapper, { mutation: statusUpdateObjectTags, status: statusTags, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Autocomplete, { freeSolo: true, multiple: true, disableClearable: true, filterSelectedOptions: true, disablePortal: true, open: tagName.length > 0, onInputChange: (event, value) => {
                            setTagName(value);
                        }, inputValue: tagName, onChange: (e, value, reason) => {
                            if (reason === 'createOption') {
                                addTag(tagName);
                            }
                        }, options: statusTags.data, getOptionLabel: (option) => typeof option === 'string' ? option : option.value, renderInput: (params) => (_jsx(TextFormItem, { ...params, autoFocus: true, label: t('tagField.label'), InputProps: {
                                ...params.InputProps,
                                endAdornment: (_jsx(Button, { onClick: () => {
                                        addTag(tagName);
                                    }, color: "secondary", variant: "contained", children: t('tagField.button') })),
                                startAdornment: _jsx(More, { sx: { ml: 1 } }),
                            } })), renderOption: (props, option) => {
                            return (_jsx(ListItem, { ...props, sx: {
                                    '&.MuiAutocomplete-option': { pl: 1, pr: 1 },
                                    '&.MuiAutocomplete-option.Mui-focused': {
                                        backgroundColor: 'transparent',
                                    },
                                    width: 'auto',
                                }, children: _jsx(Chip, { color: "warning", variant: "outlined", label: option.value, onClick: () => {
                                        addTag(option.value);
                                    }, sx: {
                                        '.MuiAutocomplete-option:hover &, .Mui-focused &': {
                                            backgroundColor: (theme) => theme.palette.warning.main,
                                            color: (theme) => theme.palette.common.white,
                                        },
                                        cursor: 'inherit',
                                    } }) }));
                        }, slotProps: {
                            paper: { sx: { boxShadow: 'none' } },
                            popper: {
                                sx: {
                                    position: 'static !important',
                                    transform: 'none !important',
                                },
                            },
                        }, ListboxProps: {
                            sx: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                maxHeight: (theme) => theme.spacing(LISTBOX_ROWS_NUMBER * LISTBOX_ROW_HEIGHT),
                                py: 0,
                            },
                        }, sx: {
                            '& .MuiInputBase-root.MuiInputBase-formControl': {
                                p: 0.25,
                                pl: 0,
                            },
                        } }), tagsFullyAssigned.length === 0 &&
                        tagsPartiallyAssigned.length === 0 && (_jsx(Typography, { children: t('noAssignedTags') })), tagsFullyAssigned.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Typography, { children: t('fullyAssignedTags') }), _jsx(Stack, { direction: "row", role: "list", spacing: 2, children: tagsFullyAssigned.map((tag) => (_jsx(Box, { role: "listitem", children: _jsx(Chip, { color: "warning", clickable: false, label: tag, onDelete: () => {
                                            unassignTag(tag);
                                        }, deleteIcon: _jsx(Close, {}) }) }, tag))) })] })), tagsPartiallyAssigned.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Typography, { children: t('partiallyAssignedTags') }), _jsx(Stack, { direction: "row", role: "list", spacing: 2, children: tagsPartiallyAssigned.map((tag) => (_jsx(Box, { role: "listitem", children: _jsx(Chip, { color: "warning", clickable: false, label: tag, onDelete: () => {
                                            unassignTag(tag);
                                        }, deleteIcon: _jsx(Close, {}) }) }, tag))) })] }))] }) }) }));
};
export default TagManagementDialog;
